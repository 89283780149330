import { render, staticRenderFns } from "./CompanySettings.vue?vue&type=template&id=111f35f5&scoped=true"
import script from "./CompanySettings.vue?vue&type=script&lang=js"
export * from "./CompanySettings.vue?vue&type=script&lang=js"
import style0 from "bootstrap/dist/css/bootstrap.css?vue&type=style&index=0&id=111f35f5&prod&scoped=true&lang=css&external"
import style1 from "bootstrap-vue/dist/bootstrap-vue.css?vue&type=style&index=1&id=111f35f5&prod&scoped=true&lang=css&external"
import style2 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "@/assets/styles/multiselect.css?vue&type=style&index=3&prod&lang=css&external"
import style4 from "@/assets/styles/beneficiary.css?vue&type=style&index=4&id=111f35f5&prod&scoped=true&lang=css&external"
import style5 from "./CompanySettings.vue?vue&type=style&index=5&id=111f35f5&prod&scoped=true&lang=css"
import style6 from "./CompanySettings.vue?vue&type=style&index=6&id=111f35f5&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "111f35f5",
  null
  
)

export default component.exports