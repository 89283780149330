<template>
  <div>
    <div class="account-title">
      <h1>{{ $t("companySettingsTitle") }}</h1>
      <p>{{ $t("userSettingsDescription") }}</p>
    </div>
    <div class="content-delivery mb-5">
      <div class="second-tabs">
        <h3 v-if="!user.username.includes('@')">
          <img src="@/assets/images/company-icon.svg" />
          &nbsp;&nbsp;&nbsp;{{ companyDetails.name }}
        </h3>
        <h3 v-else>
          {{ user.first_name + " " + user.last_name }}
        </h3>
        <div class="grey-info">
          <b-container fluid class="border-box">
            <b-row class="border-box">
              <b-col v-if="!user.username.includes('@')" class="border-box grey-info" xl="6" md="6">
                <p>&nbsp;</p>
                <p>
                  <small>{{ $t("companyCodeLabel") }}</small>
                  <br />
                  <input disabled type="text" class="formedituser" v-model="companyDetails.company_id" />
                </p>
                <p class="position-relative">
                  <small>{{ $t("fiscalCodeLabel") }}</small>
                  <br />
                  <input disabled type="text" class="formedituser" v-model="companyDetails.fiscal_code" />
                </p>
                <p class="position-relative">
                  <small>{{ $t("VATLabelAFF") }}</small>
                  <br />
                  <input disabled type="text" class="formedituser" v-model="companyDetails.VAT" />
                </p>
                <p class="position-relative">
                  <small>{{ $t("invoiceAddressLabel") }}</small>
                  <br />
                  <input disabled type="text" class="formedituser" v-model="companyDetails.address" />
                </p>
                <!-- <p v-if="this.userRole === 'AFF' || this.userRole === 'AFA'">
                  <small>*{{ $t("ibanLabel") }}</small>
                  <br />
                  <input
                    disabled
                    type="text"
                    class="formedituser"
                    v-model="companyDetails.products[0].iban"
                  />
                  <br />
                  <small>{{ $t("changeInfoCompanyText") }}</small>
                  <br />
                  <br />
                  <a
                    class="notification-action"
                    href="https://app.uponline.ro/doc/ro/document_afiliati.pdf"
                  >
                    {{ $t("downloadFormChangeInfoCompanyLabel") }}
                  </a>
                </p> -->
                <br />
                <p>
                  <span v-html="$t('uploadFileForVATText')"></span>
                </p>
                <p>
                  <b-form-file v-model="uploadFileForAddress" id="uploadFileForAddress" ref="file"
                    accept=".jpg, .jpeg, .pdf, .png" :placeholder="[[$t('uploadFileLabel')]]" class="formedituser"
                    @input="uploadFile('address')"></b-form-file>
                </p>
                <br />
                <p class="d-flex justify-content-between align-items-center">
                  <a class="notification-action custom-notification-action" @click="uploadFileToServer()">{{
                    $t("sendFileText") }}</a>
                </p>
              </b-col>
              <b-col v-else class="border-box grey-info" xl="6" md="6">
                <p>&nbsp;</p>
                <p>
                  <small>{{ $t("surnameFieldLabel") }}</small>
                  <br />
                  <input id="first_name" disabled type="text" class="formedituser" v-model="formData.firstName"
                    @keyup="updateInputCharacters()" />
                </p>
                <p>
                  <small>{{ $t("lastNameField") }}</small>
                  <br />
                  <input id="last_name" disabled type="text" class="formedituser" v-model="formData.lastName"
                    @keyup="updateInputCharacters()" />
                </p>
                <p>
                  <small>{{ $t("emailField") }}</small>
                  <br />
                  <input id="email" disabled type="email" class="formedituser" v-model="user.email" />
                </p>
                <!-- <p>
                  <small>{{ $t("mobileField") }}</small>
                  <br>
                  <input
                    id="mobile_phone"
                    :disabled="!editMode"
                    type="text"
                    class="formedituser"
                    v-model="formData.mobilePhone"
                    @keyup="updateInputCharacters()"
                  />
                </p> -->
                <p v-if="!this.editMode &&
                  customer &&
                  customer.role &&
                  customer.role !== 'FAC' &&
                  !customer.state_owned &&
                  customer.user_config &&
                  customer.user_config.roles &&
                  customer.user_config.roles.includes('EDITOR')
                  ">
                  <a class="notification-action custom-notification-action" @click="editData()">{{ $t("editButtonLabel")
                  }}</a>
                </p>
                <p v-if="this.editMode" class="d-flex align-items-center justify-content-between">
                  <a class="notification-action" @click="saveNewData()">{{
                    $t("saveChangesLabel")
                  }}</a>
                  <a class="resetButton" @click="resetData()">{{
                    $t("cancelChangesLabel")
                  }}</a>
                </p>
              </b-col>
              <b-col class="border-box grey-info" offset-xl="2" offset-md="2" xl="4" md="4">
                <form>
                    <small>{{ $t("placeholderOldPassword") }}</small>
                    <br />
                  <div class="password">
                    <input v-model="oldPassword" required class="formedituser"
                      :type="showPassword ? 'text' : 'password'" />
                    <i v-on:click="showPassword = !showPassword">
                      <font-awesome-icon :icon="showPassword ? 'eye-slash' : 'eye'" />

                    </i>
                  </div>
                    <small>{{ $t("passwordField") }}</small>
                    <br />
                  <div class="password">
                    <input v-model="newPassword" @input="validatePassword" required
                      class="formedituser" :type="showNewPassword ? 'text' : 'password'" />
                    <i v-on:click="showNewPassword = !showNewPassword">
                      <font-awesome-icon :icon="showNewPassword ? 'eye-slash' : 'eye'" />

                    </i>
                  </div>
                    <small>{{ $t("confirmPasswordField") }}</small>
                    <br />
                  <div class="password">
                    <input required :style="confirmPasswordStyle"
                      @input="validatePassword" v-model="confirmPassword" class="formedituser"
                      :type="showConfirmPassword ? 'text' : 'password'" />
                    <i v-on:click="showConfirmPassword = !showConfirmPassword">
                      <font-awesome-icon :icon="showConfirmPassword ? 'eye-slash' : 'eye'" />

                    </i>
                  </div>
                  <p>
                    <password v-model="newPassword" :strength-meter-only="true" />
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.passwordLengthValid
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                      " />
                    &nbsp; {{ $t("passwordCriteria1Text") }}
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsLetter
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                      " />
                    &nbsp; {{ $t("passwordCriteria2Text") }}
                  </p>
                  <p>
                    <img v-if="!isIE" :src="this.validPassword.containsNumber
                        ? require('../../assets/images/yes.png')
                        : require('../../assets/images/no.png')
                      " />
                    &nbsp; {{ $t("passwordCriteria3Text") }}
                  </p>
                  <br />
                  <p>
                    <a @click="submitUserPassword()" class="notification-action">{{ $t("changePasswordLabel") }}</a>
                  </p>
                </form>
              </b-col>
            </b-row>
          </b-container>
        </div>
        <br />
      </div>
      <div v-if="!user.username.includes('@') &&
        this.userRole !== 'AFA' &&
        this.companyDetails.role &&
        this.companyDetails.role !== 'FAC' &&
        !this.companyDetails.state_owned
        " class="second-tabs">
        <h3>{{ $t("requestMandateTitleSection") }}</h3>
        <div class="grey-info">
          <b-container fluid class="border-box">
            <b-row class="border-box">
              <b-col class="border-box" xl="12" md="12">
                <p></p>
                <p v-if="this.userRole === 'AFF' &&
                  (!companyDetails.ral ||
                    (companyDetails.ral && !companyDetails.ral.email) ||
                    (companyDetails.ral &&
                      companyDetails.ral.email &&
                      !companyDetails.ral.email.includes('@'))) &&
                  companyDetails.user_config &&
                  companyDetails.user_config.roles &&
                  !companyDetails.user_config.roles.includes('EDITOR')
                  ">
                  {{ $t("noRalText") }}
                </p>
                <p v-else>
                  {{
                    $t(
                      "requestMandateText_" +
                      this.companyDetails.role +
                      "_" +
                      this.mandateStatus
                    )
                  }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="border-box" xl="4" md="4" offset-xl="8" offset-md="8">
                <a v-if="this.mandateStatus !== 'V' &&
                    this.companyDetails.ral &&
                    this.companyDetails.ral.email
                    " class="notification-action" @click="requestMandate()">
                  {{ $t("requestMandateTextButton_" + this.mandateStatus) }}
                </a>
                <br />
                <br />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>

      <div v-if="!user.username.includes('@') &&
        this.userRole === 'AFF' &&
        this.userRole !== 'AFA' &&
        this.userRole !== 'CST' &&
        this.companyDetails.role !== 'FAC' &&
        !this.companyDetails.state_owned
        " class="second-tabs">
        <h3>{{ $t("requestNotifTitleSection") }}</h3>
        <div class="grey-info">
          <b-container fluid class="border-box">
            <b-row class="border-box">
              <b-col class="border-box" xl="12" md="12">
                <p></p>
                <p v-if="!companyDetails.ral &&
                  companyDetails.ral &&
                  !companyDetails.ral.email &&
                  companyDetails.ral &&
                  companyDetails.ral.email &&
                  !companyDetails.ral.email.includes('@')
                  ">
                  {{ $t("requestNotifText") }}
                </p>
                <p v-else>{{ $t("requestNotifTextRal") }}</p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="border-box" xl="8" md="8">
                <small>{{ $t("requestNotifEmailLabel") }}</small>
                <form >
                  <input id="email_notif" type="text" class="formedituser" v-model="emailNotif" />
                </form>
              </b-col>
              <b-col class="border-box" xl="4" md="4">
                <a class="notification-action" @click="requestNotif()">
                  {{ $t("requestNotifTextButton") }}
                </a>
              </b-col>
            </b-row>
            <br />
          </b-container>
        </div>
      </div>
    </div>
    <!-- notificare pentru trecere de la smsfull la push -->
    <div class="content-delivery mb-5" v-if="!user.username.includes('@') &&
        this.userRole === 'CST' &&
        this.companyDetails.role !== 'FAC' 
        && this.companyDetails.has_sms_full
        ">
      <div 
         class="second-tabs">
        <h3>{{ $t("requestNotificationSmsFullTitle") }}</h3>
        <div class="grey-info">
          <b-container fluid class="border-box">
            <b-row class="border-box">
              <b-col class="border-box" xl="12" md="12">
                <p></p>
                <p v-html='$t("requestNotificationSmsFullText")'>
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="border-box">
                <a  class="notification-action" @click="requestSMSNotification()">
                  {{ $t("requestNotificationSmsFullButton" ) }}
                </a>
                <br />
                <br />
              </b-col>
            </b-row>
          </b-container>
        </div>
      </div>
    </div>
        
    <div v-if="user.username.includes('@')" class="content-delivery mb-5">
      <div class="second-tabs">
        <div class="">
          <h3 class="title-address-table">
            <strong>{{ $t("manageCompaniesTitleSection") }}</strong>
          </h3>
          <form >
            <multiselect v-model="selectedManageCompany" :options="manageCompanies" :placeholder="$t('selectLabel')"
              select-label="" selected-label="" deselect-label="" openDirection="bottom" track-by="company_id"
              :custom-label="customLabel" :loading="isLoading" @input="subsidiarySelected()">
            </multiselect>
          </form>
        </div>
        <table cellpadding="10" cellspacing="0" width="100%" class="hover-table invoices-table" align="center">
          <thead>
            <tr>
              <td>
                <strong>{{ $t("searchByCompanyName") }}</strong>
              </td>
              <td>
                <strong>{{ $t("codallLabel") }}</strong>
              </td>
              <td class="priority-4">
                <strong>{{ $t("typeUPPartner") }}</strong>
              </td>
              <td class="priority-5">
                <strong>{{ $t("roleLabel") }}</strong>
              </td>
              <td class="priority-5">
                <strong>{{ $t("invoicesFilterStatus") }}</strong>
              </td>
              <td>
                <strong>{{ $t("beneficiariesTableAction") }}</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-if="this.manageCompanies === null ||
              this.manageCompanies === undefined
              ">
              <td colspan="6">
                <div class="loading">
                  <img src="@/assets/images/loading.gif" />
                </div>
              </td>
            </tr>
            <template v-for="(comp, index) in filterManageCompany">
              <tr :key="index" class="trhover parent" :class="index % 2 === 0 && 'gri-cell'">
                <td valign="middle">
                  <a class="
                      detailsOrderMobile
                      priority-show-5
                      display-inline-block
                    " @click="selectManageCompanyMobile(index)">
                    <font-awesome-icon icon="minus-circle" v-if="detailsMobile && comp === manageCompanySelectedMobile
                      " />
                    <font-awesome-icon icon="plus-circle" v-else />&nbsp;&nbsp;
                  </a>
                  <strong>{{ comp.name }}</strong>
                </td>
                <td valign="middle">
                  {{ comp.company_id }}
                </td>
                <!-- <td valign="middle" class="priority-3">
                  {{ comp.fiscal_code }}
                </!-->
                <td valign="middle" class="priority-4">
                  {{ $t("userTypeLabel_" + comp.role) }}
                </td>
                <td valign="middle" class="priority-5">
                  <span v-if="comp.user_config">{{
                    $t("userModeLabel_" + comp.user_config.roles[0])
                  }}</span>
                </td>
                <td valign="middle" class="priority-5">
                  {{ $t("companyStatus_" + comp.active) }}
                </td>
                <td valign="middle">
                  <a class="action-button mb-1" @click="confirmDeleteCompany(comp)">
                    <span>{{ $t("deletePOSLabel") }}</span>
                  </a>
                </td>
              </tr>
              <tr :key="index + manageCompanies.length" class="child" :class="index % 2 === 0 && 'gri-cell'"
                v-show="detailsMobile && comp === manageCompanySelectedMobile">
                <td colspan="12">
                  <!-- <p class="priority-show-3">
                    <strong >{{ $t("vat") }} : &nbsp;&nbsp;</strong>
                    <span>{{ comp.fiscal_code }}</span>
                  </p> -->
                  <p class="priority-show-4">
                    <strong>{{ $t("typeUPPartner") }} : &nbsp;&nbsp;</strong>
                    <span>{{ $t("userTypeLabel_" + comp.role) }}</span>
                  </p>
                  <p class="priority-show-5">
                    <strong>{{ $t("roleLabel") }} : &nbsp;&nbsp;</strong>
                    <span v-if="comp.user_config">{{
                      $t("userModeLabel_" + comp.user_config.roles[0])
                    }}</span>
                  </p>
                  <p class="priority-show-5">
                    <strong>{{ $t("vat") }} : &nbsp;&nbsp;</strong>
                    <span>{{ $t("companyStatus_" + comp.active) }}</span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="tabs-container">
      <div class="tabs">
        <a v-for="(entry, index) in companyDetails.products" :key="entry.name" @click="selectProduct(index)" :class="entry.name === (selectedProduct ? selectedProduct.name : '') &&
          'active'
          ">
          <img :src="require('@/assets/images/logo-up-' +
              (entry.card_profile
                ? entry.card_profile
                : entry.card
                  ? entry.card
                  : entry.paper) +
              '.png')
            " />
        </a>
      </div>
    </div>
    <div class="content-delivery mb-5">
      <div class="second-tabs">
        <a v-if="this.selectedProduct && this.selectedProduct.paper !== 0"
          class="tickets-type paper cursor-pointer text-decoration-none" :class="this.environment === 'paper' && 'active remove-cursor-pointer'
            " @click="setEnvironment('paper')">{{ $t("productTypePaper") }}</a>
        <a v-if="this.selectedProduct && this.selectedProduct.card !== 0"
          class="tickets-type cardst cursor-pointer text-decoration-none"
          :class="this.environment === 'card' && 'active remove-cursor-pointer'" @click="setEnvironment('card')">{{
            $t("productTypeCard") }}</a>
        <br clear="all" />
      </div>
      <div class="second-tabs">
        <b-container v-if="this.userRole !== 'AFF' && this.userRole !== 'AFA'" fluid class="border-box">
          <form >
            <b-row class="border-box">
              <b-col class="border-box" md="12">
                <h3>{{ $t("orderPersonTitle") }}</h3>
              </b-col>
            </b-row>
            <b-row v-if="filterOrderPerson" class="border-box">
              <b-col class="border-box" xl="3" lg="6" md="6">
                <p>
                  <small>{{ $t("deliveryPointContactSurname") }}</small>
                  <br />
                  <input type="text" v-model="filterOrderPerson.first_name" class="formedituser" :disabled="!editMode" />
                </p>
              </b-col>
              <b-col class="border-box" xl="3" lg="6" md="6">
                <p>
                  <small>{{ $t("deliveryPointContactName") }}</small>
                  <br />
                  <input type="text" v-model="filterOrderPerson.last_name" class="formedituser" :disabled="!editMode" />
                </p>
              </b-col>
              <!-- <b-col class="border-box" xl="2" lg="6" md="6">
                <p>
                  <small>{{ $t("workingPointPersonPosition") }}</small>
                  <br>
                  <input
                    type="text"
                    v-model="filterOrderPerson.function"
                    class="formedituser"
                    disabled
                  />
                </p>
              </b-col> -->
              <b-col class="border-box" xl="3" lg="6" md="6">
                <p>
                  <small>{{ $t("deliveryPointContactEmail") }}</small>
                  <br />
                  <input type="text" v-model="filterOrderPerson.email" class="formedituser" maxlength="50" disabled />
                </p>
              </b-col>
              <b-col class="border-box" xl="3" lg="6" md="6">
                <p>
                  <small>{{ $t("deliveryPointContactMobile") }}</small>
                  <br />
                  <input type="text" v-model="filterOrderPerson.mobile_phone" class="formedituser" maxlength="14"
                    disabled />
                </p>
              </b-col>
            </b-row>
            <b-row v-else-if="loadingOrderPersons" class="border-box">
              <b-col class="border-box" md="12">
                <div class="loading">
                  <img src="@/assets/images/loading.gif" />
                </div>
              </b-col>
            </b-row>
            <b-row v-else class="border-box">
              <b-col class="border-box" md="12">
                <p>{{ $t("noPersonOrders") }}</p>
              </b-col>
            </b-row>
          </form>
        </b-container>
        <b-container fluid class="border-box">
          <form >
            <b-row class="border-box">
              <b-col class="border-box" md="12">
                <h3>{{ $t("customerBankAccountTitle") }}</h3>
              </b-col>
            </b-row>
            <b-row v-if="filterCustomerIBAN" class="border-box">
              <b-col class="border-box" xl="3" lg="6" md="6">
                <p>
                  <small>{{ $t("ibanLabel") }}</small>
                  <br />
                  <input v-if="!editMode" type="text" v-model="filterCustomerIBAN.iban" class="formedituser" disabled />
                  <input v-else type="text" v-model="ibanData.iban" class="formedituser" />
                </p>
              </b-col>
              <!-- <b-col
                v-if="!addIbanBool"
                class="border-box"
                xl="3"
                lg="6"
                md="6"
              >
                <p>
                  <small>{{ $t("customerBankTitle") }}</small>
                  <br />
                  <input
                    type="text"
                    v-model="filterCustomerIBAN.bankBranch"
                    class="formedituser"
                    disabled
                  />
                </p>
              </b-col> -->
              <b-col v-if="this.userRole !== 'AFF' && this.userRole !== 'AFA'" class="border-box" xl="12" lg="12" md="12">
                <b-row v-if="!editMode" class="border-box">
                  <b-col class="border-box" xl="6" lg="6" md="6">
                    <p>
                      <a class="notification-action custom-notification-action" @click="editIBAN()">{{
                        $t("editButtonLabel") }}</a>
                    </p>
                  </b-col>
                </b-row>
                <b-row v-else class="border-box">
                  <b-col class="border-box" xl="6" lg="6" md="6">
                    <p>
                      <a class="notification-action" @click="showModalPopup('modal_popup_confirm', true)">{{
                        $t("saveChangesLabel") }}</a>
                    </p>
                  </b-col>
                  <b-col class="border-box" xl="6" lg="6" md="6">
                    <p class="line-height-43 text-right">
                      <a class="resetButton" @click="editMode = false">
                        <i>{{ $t("cancelChangesLabel") }}</i>
                      </a>
                    </p>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row v-else-if="loadingCustomerIBAN" class="border-box">
              <b-col class="border-box" md="12">
                <div class="loading">
                  <img src="@/assets/images/loading.gif" />
                </div>
              </b-col>
            </b-row>
            <b-row v-else class="border-box">
              <b-col class="border-box" md="12">
                <p>{{ $t("noCustomerIBAN") }}</p>
                <a class="notification-action" @click="addIBAN()">{{
                  $t("addIBANText")
                }}</a>
              </b-col>
            </b-row>
          </form>
        </b-container>
      </div>
    </div>
    <div id="modal_popup_info" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.companyDataError">
          <p v-html="this.companyDataError.description"></p>
        </div>
        <div v-else-if="changePasswordSuccess">
          <p>{{ $t("changePasswordSuccess") }}</p>
        </div>
        <div v-else-if="deleteCompanySuccess">
          <p>{{ $t("deleteCompanySuccessMessage") }}</p>
        </div>
        <div v-else-if="mandateSentSuccess">
          <p>{{ $t("mandateSentSuccessMessage") }}</p>
        </div>
        <div v-else-if="documentSentSuccess">
          <p>{{ $t("documentSentSuccess") }}</p>
        </div>
        <div v-else-if="smsNotificationSuccess">
          <p>{{ $t("requestNotificationSmsFullSuccess") }}</p>
        </div>
        <div v-else-if="!smsNotificationSuccess">
          <p>{{ $t("requestNotificationSmsFullFailure") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_confirmDelete" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_confirmDelete', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <p v-if="manageCompanies.length > 1">
          {{ $t("deleteCompanyText") }}
        </p>
        <p v-else>
          {{ $t("deleteLastCompanyText") }}
        </p>
        <div class="buttons">
          <a class="yesButton" @click="deleteCompany()">{{
            $t("notificationsChooseOptionYes")
          }}</a>
          <a class="noButton" @click="showModalPopup('modal_popup_confirmDelete', false)">{{
            $t("notificationsChooseOptionNo") }}</a>
        </div>
      </div>
    </div>
    <div id="modal_popup_confirm" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_confirm', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <!-- <p>{{ $t("deliveryPointConfirmData") }}</p>
        <br> -->
        <b-form-checkbox id="allProducts" v-model="allProducts" name="allProducts">
          <span>{{ $t("ibanAllProductsText") }}</span>
        </b-form-checkbox>
        <br />
        <!-- <small v-if="this.environment === 'card'">{{
          $t("warningCardAddressPoint")
        }}</small>
        <br />-->
        <br />
        <a class="notification-action" @click="submitIBAN()">{{
          $t("saveChangesLabel")
        }}</a>
      </div>
    </div>
    <div id="modal_popup_info_iban" class="modal-popup">
      <div class="modal-popup-content">
        <a @click="showModalPopup('modal_popup_info_iban', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <div v-if="this.sentCustomerIBANError">
          <p>
            {{ $t("errorSentCustomerIBAN_" + this.sentCustomerIBANError.code) }}
          </p>
        </div>
        <div v-else>
          <p>{{ $t("sentAddressPointSuccess") }}</p>
        </div>
      </div>
    </div>
    <div id="modal_popup_notif" class="modal-popup">
      <div class="modal-popup-content-notif">
        <a @click="showModalPopup('modal_popup_notif', false)" class="close-button">
          <img src="@/assets/images/close-button.svg" />
        </a>
        <LinkFrame :urlAddress="'https://crm.uponline.ro/doc/notif-aff?uuid=' + this.uuid
          " />
      </div>
    </div>
  </div>
</template>

<script>
import httpServiceUser from "@/http/http-user.js";
import httpServiceCustomer from "@/http/http-customer.js";
import httpServiceAffiliate from "@/http/http-affiliate.js";
import httpServiceCommon from "@/http/http-common.js";
import validator from "@/plugins/fieldValidation";
import Cookies from "js-cookie";
import Password from "vue-password-strength-meter";
import LinkFrame from "@/components/beneficiary/LinkFrame.vue";
import utils from "@/plugins/utils.js";
import { saveAs } from "file-saver";

import {
  BContainer,
  BRow,
  BCol,
  BFormFile,
  BFormCheckbox
} from "bootstrap-vue";
import fieldValidation from "../../plugins/fieldValidation";

export default {
  components: {
    Password,
    LinkFrame,
    "b-container": BContainer,
    "b-row": BRow,
    "b-col": BCol,
    "b-form-file": BFormFile,
    "b-form-checkbox": BFormCheckbox
  },
  directives: {
    // "b-tooltip": VBTooltip,
  },
  props: {
    companyDetails: Object,
    user: Object,
    userRole: String,
    msg: String
  },
  data() {
    return {
      isIE: null,
      companyDataError: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: "",
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      validPassword: {
        passwordValid: false,
        passwordLengthValid: false,
        containsLetter: false,
        containsNumber: false,
        confirmPasswordValid: false
      },
      editMode: false,
      currentPassword: null,
      formData: null,
      manageCompanies: this.user.companies,
      manageCompanySelectedMobile: null,
      detailsMobile: false,
      selectedCompanyForDelete: null,
      selectedManageCompany: "",
      isLoading: false,
      deleteCompanySuccess: false,
      changePasswordSuccess: false,
      mandateSentSuccess: false,
      uploadFileForVAT: null,
      uploadFileForAddress: null,
      payVAT: false,
      uploadDocument: {
        content: null,
        type: "customer",
        docType: ""
      },
      documentSentSuccess: false,
      smsNotificationSuccess: false,
      selectedProduct: null,
      orderPersons: null,
      environment: null,
      loadingOrderPersons: false,
      loadingCustomerIBAN: false,
      orderPersonsError: null,
      customerIBANError: null,
      customerIBAN: null,
      ibanData: null,
      allProducts: false,
      sentCustomerIBANError: null,
      mandate: null,
      newIBAN: null,
      addIbanBool: false,
      emailNotif: null,
      uuid: null
    };
  },
  methods: {
    selectManageCompanyMobile(index) {
      this.manageCompanySelectedMobile = this.manageCompanies[index];
      this.detailsMobile = !this.detailsMobile;
    },
    validatePassword() {
      this.validPassword = validator.validatePassword(
        this.newPassword,
        this.confirmPassword
      );
    },
    submitUserPassword() {
      this.changePasswordSuccess = false;
      this.deleteCompanySuccess = false;
      this.companyDataError = null;
      this.showModalPopup("modal_popup_info", true);
      if (!this.validPassword.passwordValid) {
        this.companyDataError = {
          description: "Invalid password",
          code: ""
        };
        return;
      } else {
        httpServiceUser.userService
          .confirmUserData(
            this.url,
            {
              id: this.companyDetails.company_id,
              current_password: this.oldPassword,
              new_password: this.newPassword
            },
            this.token
          )
          .then(function (response) {
            return response;
          })
          .catch(error => {
            this.companyDataError = error.response.code;
          })
          .then(response => {
            if (response.status == 200) {
              this.oldPassword = "";
              this.newPassword = "";
              this.confirmPassword = "";
              this.changePasswordSuccess = true;
            } else if (response.status == 400) {
              this.companyDataError = response.data;
            } else {
              this.companyDataError = { description: response.code };
            }
          });
      }
    },
    showModalPopup(elementId, show) {
      if (show) {
        document.getElementById(elementId).style.visibility = "visible";
      } else {
        document.getElementById(elementId).style.visibility = "hidden";
      }
    },
    editData() {
      this.editMode = true;
    },
    saveNewData() {
      this.changePasswordSuccess = false;
      this.showModalPopup("modal_popup_loading", true);
      httpServiceUser.userService
        .confirmUserData(
          this.url,
          {
            id: this.companyDetails.company_id,
            // current_password: this.currentPassword,
            last_name: this.formData.lastName,
            first_name: this.formData.firstName
            // mobile_phone: this.formData.mobilePhone
          },
          this.token
        )
        .then(function (response) {
          return response;
        })
        .then(response => {
          if (response.status == 200) {
            this.companyDataError = null;
            // this.currentPassword = "";
            this.changePasswordSuccess = true;
            this.resetData();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          } else if (response.status == 400) {
            this.companyDataError = response.data;
            this.resetData();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          } else {
            this.companyDataError = { description: response.code };
            this.resetData();
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            window.location.reload();
          }
        })
        .catch(error => {
          if (error.response) {
            this.companyDataError = error.response.data;
          } else {
            this.companyDataError = this.$t("errorMessage_undefined");
          }
          this.resetData();
          // this.showModalPopup("modal_popup_changeData", false);
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
          window.location.reload();
        });
    },
    resetData() {
      this.formData = {
        lastName: this.user.last_name,
        firstName: this.user.first_name
        // mobilePhone: this.user.mobile_phone
      };
      this.editMode = false;
    },
    onlyAlphabetCharacters(variable) {
      if (/^[a-zA-Z]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[0-9&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g, "");
      }
    },
    onlyNumericCharacters(variable) {
      if (/^[0-9]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(
          /[a-zA-Z&\/\\|`#@\[\]\-,+=()$~%.'":*!?<>{}]/g,
          ""
        );
      }
      return variable;
    },
    onlyAlphaNumericCharacters(variable) {
      if (/^[a-zA-Z0-9@.]*$/i.test(variable)) {
        return variable;
      } else {
        return variable.replace(/[&\/\\|`#\[\]\-,+=()$~%'":*!?<>{}]/g, "");
      }
    },
    updateInputCharacters() {
      this.formData.lastName = this.onlyAlphabetCharacters(
        this.formData.lastName
      );
      this.formData.firstName = this.onlyAlphabetCharacters(
        this.formData.firstName
      );
      // this.formData.mobilePhone = this.onlyNumericCharacters(
      //   this.formData.mobilePhone
      // );
      this.formData.email = this.onlyAlphaNumericCharacters(
        this.formData.email
      );
      this.$forceUpdate();
    },
    confirmDeleteCompany(company) {
      this.showModalPopup("modal_popup_confirmDelete", true);
      this.selectedCompanyForDelete = company;
    },
    deleteCompany() {
      this.changePasswordSuccess = false;
      this.deleteCompanySuccess = false;
      this.companyDataError = null;
      this.showModalPopup("modal_popup_confirmDelete", false);
      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .deleteCompanyFromUser(
          this.selectedCompanyForDelete.company_id,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            if (this.user.companies.length === 1) {
              this.logout();
            } else {
              this.deleteCompanySuccess = true;
              this.showModalPopup("modal_popup_loading", false);
              this.showModalPopup("modal_popup_info", true);
              window.location.reload();
            }
          } else {
            this.companyDataError = { description: response.status };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response) {
            this.companyDataError = { description: error.response.status };
          } else {
            this.companyDataError = { description: "undefined" };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    customLabel({ company_id, name }) {
      return `${company_id} – ${name}`;
    },
    logout() {
      Cookies.remove("userLink");
      Cookies.remove("user_role");
      Cookies.remove("token");
      let hasFxlAccess = this.flxUserLevel().hasAccess;
      Cookies.remove("flexbenClient");
      Cookies.remove("flxCookie");
      if (hasFxlAccess) {
        this.$router.push({
          name: "loginMultibenefits",
          params: { loginUserSelected: false }
        });
      } else {
        this.$router.push({
          name: "login",
          params: { loginUserSelected: false }
        });
      }
    },
    requestMandate() {
      this.mandateSentSuccess = false;
      this.companyDataError = null;
      this.showModalPopup("modal_popup_loading", true);

      httpServiceUser.userService
        .addNewUserForManageCompany(
          this.companyDetails.company_id,
          {
            flow: "add_role_customer",
            company_type: this.userRole
          },
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.mandateSentSuccess = true;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          } else {
            this.companyDataError = response.data;
            // this.companyDataError = { description: response.status };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response) {
            this.companyDataError = error.response.data;
          } else {
            this.companyDataError = { description: "undefined" };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    requestNotif() {
      this.showModalPopup("modal_popup_loading", true);
      if (
        this.emailNotif === null ||
        !fieldValidation.validateEmail(this.emailNotif)
      ) {
        this.companyDataError = {
          description: "Email invalid",
          code: ""
        };
        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_info", true);

        return;
      }
      httpServiceAffiliate.affiliateService
        .getNotifLink(
          this.companyDetails.company_id,
          {
            id: this.companyDetails.company_crm_id,
            email: this.emailNotif
          },
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.uuid = response.data.uuid;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_notif", true);
          } else {
            this.companyDataError = response.data;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          if (error.response) {
            this.companyDataError = error.response.data;
          } else {
            this.companyDataError = { description: "undefined" };
          }
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
        });
    },
    requestSMSNotification(){
      this.showModalPopup("modal_popup_loading", true);
      this.smsNotificationSuccess = false;
      let commercial = this.companyDetails.products[0].commercial;
      let smsNotificationData = {clientName: this.companyDetails.name.trim(),
        gestName: commercial ? commercial.first_name.trim() + " " + commercial.last_name.trim() : "",
        codhd: this.companyDetails.company_id
      };

      httpServiceCustomer.customerService
          .updateCustomerSMSOption(
            this.companyDetails.company_id,
            smsNotificationData,
            Cookies.get("token")
          )
          .then(response => {
            if (response.status === 200 || response.status === 204) {
              var blob = new Blob(
                [utils.base64ToArrayBuffer(response.data.content)],
                {
                  type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheetapplication/vnd.ms-excel"
                }
              );
              saveAs(blob, "Notificare SMS - Push.pdf");
              this.smsNotificationSuccess = true;
            } else {
              this.requestSmsNotificationError = { code: "NOT_SENT" };
            }
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          })
          .catch(error => {
            if (error.response) {
              this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            }
          });
    },
    uploadFile(type) {
      var reader = new FileReader();
      reader.onload = e => {
        if (!e) {
          this.uploadDocument.content = btoa(reader.content);
        } else {
          this.uploadDocument.content = btoa(e.target.result);
        }
      };

      this.uploadDocument.docType = type;
      //For Browsers other than IE.
      if (type === "address") {
        if (reader.readAsBinaryString) {
          this.uploadDocument.docName = this.uploadFileForAddress.name;
          reader.readAsBinaryString(this.uploadFileForAddress);
        } else {
          alert("This browser does not support HTML5.");
        }

        if (!this.uploadFileForAddress) {
          return;
        }
      } else {
        if (reader.readAsBinaryString) {
          this.uploadDocument.docName = this.uploadFileForVAT.name;
          reader.readAsBinaryString(this.uploadFileForVAT);
        } else {
          alert("This browser does not support HTML5.");
        }

        if (!this.uploadFileForVAT) {
          return;
        }
      }
    },

    uploadFileToServer() {
      this.showModalPopup("modal_popup_loading", true);

      this.companyDataError = null;
      this.documentSentSuccess = false;

      var fileData = {
        content: this.uploadDocument.content,
        code: this.companyDetails.company_id,
        type: 1,
        data: {
          docType: this.uploadDocument.docType,
          docName: this.uploadDocument.docName,
          payVat: this.payVAT
        }
      };

      httpServiceCustomer.customerService
        .uploadFileForChangeCompanySettings(
          this.companyDetails.company_id,
          fileData,
          this.token
        )
        .then(response => {
          if (response.status == 200) {
            this.documentSentSuccess = true;
            this.uploadFileForAddress = null;
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
            this.companyDataError = null;
          } else {
            this.documentSentSuccess = false;
            this.companyDataError = {
              code: response.status,
              description: this.$t("errorMessage_server_error")
            };
            this.showModalPopup("modal_popup_loading", false);
            this.showModalPopup("modal_popup_info", true);
          }
        })
        .catch(error => {
          this.documentSentSuccess = false;
          this.companyDataError = {
            code: error.response.status,
            description: this.$t("errorMessage_server_error")
          };
          this.showModalPopup("modal_popup_loading", false);
          this.showModalPopup("modal_popup_info", true);
          this.editMode = false;
        });
    },
    selectProduct(index) {
      if (this.companyDetails.products.size > 0) {
        this.selectedProduct = this.companyDetails.products[index];
        this.environment = this.selectedProduct.paper > 0 ? "paper" : "card";
        this.editMode = false;
        if (
          this.filterCustomerIBAN &&
          this.filterCustomerIBAN.bankBranch === null
        ) {
          this.addIbanBool = true;
        } else {
          this.addIbanBool = false;
        }
      }
    },
    getOrderPerson() {
      this.orderPersonsError = null;
      this.loadingOrderPersons = true;

      httpServiceCustomer.customerService
        .getOrderPerson(this.companyDetails.company_id, this.token)
        .then(response => {
          if (response.status == 200) {
            this.orderPersons = response.data;
            this.loadingOrderPersons = false;
          } else {
            this.orderPersonsError = response.status;
            this.loadingOrderPersons = false;
          }
        })
        .catch(error => {
          this.orderPersonsError = error.response.status;
          this.loadingOrderPersons = false;
        });
    },
    getCustomerIBAN() {
      this.customerIBANError = null;
      this.loadingCustomerIBAN = true;

      let url = "?type=";
      if (this.userRole === "AFF" || this.userRole === "AFA") {
        url += "affiliate";
      } else {
        url += "customer";
      }

      httpServiceCommon.companyService
        .getCompanyIBAN(this.companyDetails.company_id, url, this.token)
        .then(response => {
          if (response.status == 200) {
            this.customerIBAN = response.data;
            this.loadingCustomerIBAN = false;
          } else {
            this.customerIBANError = response.status;
            this.loadingCustomerIBAN = false;
          }
        })
        .catch(error => {
          this.customerIBANError = error.response.status;
          this.loadingCustomerIBAN = false;
        });
    },
    editIBAN() {
      this.ibanData = {
        allProducts: this.allProducts,
        bankBranch: null,
        iban: this.filterCustomerIBAN.iban,
        societyId: this.companyDetails.company_id,
        productId: this.filterCustomerIBAN.productId
      };
      this.editMode = true;
    },
    submitIBAN() {
      this.sentCustomerIBANError = null;
      this.showModalPopup("modal_popup_loading", true);
      this.ibanData.allProducts = this.allProducts;

      if (this.ibanData.iban.length === 0) {
        this.sentCustomerIBANError = { code: "NO_IBAN" };
        this.closeAndOpenPopup("submitCustomerIBAN");
        return;
      } else if (!validator.validateIBAN(this.ibanData.iban)) {
        this.sentCustomerIBANError = { code: "NO_IBAN_VALID" };
        this.closeAndOpenPopup("submitCustomerIBAN");
        return;
      } else {
        httpServiceCustomer.customerService
          .updateCustomerIBAN(
            this.companyDetails.company_id,
            this.ibanData,
            Cookies.get("token")
          )
          .then(response => {
            if (response.status === 200 || response.status === 204) {
              this.sentCustomerIBANError = null;
              this.editMode = false;
              this.getCustomerIBAN();
              this.closeAndOpenPopup("submitCustomerIBAN");
            } else if (response.status === 400) {
              this.sentCustomerIBANError = { code: "NO_IBAN_VALID" };
              this.editMode = false;
              this.closeAndOpenPopup("submitCustomerIBAN");
            } else {
              this.sentCustomerIBANError = { code: "NOT_SENT" };
              this.editMode = false;
              this.closeAndOpenPopup("submitCustomerIBAN");
            }
          })
          .catch(error => {
            if (error.response) {
              if (error.response.status === 400) {
                this.sentCustomerIBANError = { code: "NO_IBAN_VALID" };
                this.editMode = false;
                this.closeAndOpenPopup("submitCustomerIBAN");
              } else {
                this.sentCustomerIBANError = { code: "NOT_SENT" };
                this.editMode = false;
                this.closeAndOpenPopup("submitCustomerIBAN");
              }
            } else {
              this.sentCustomerIBANError = { code: "NOT_SENT" };
              this.editMode = false;
              this.closeAndOpenPopup("submitCustomerIBAN");
            }
          });
      }
    },
    closeAndOpenPopup(type) {
      if (type === "submitCustomerIBAN") {
        this.showModalPopup("modal_popup_loading", false);
        this.showModalPopup("modal_popup_confirm", false);
        this.showModalPopup("modal_popup_info_iban", true);
      }
    },
    getMandateStatus() {
      this.companyDataError = null;
      httpServiceUser.userService
        .getFlowStatusCompany(
          this.companyDetails.company_id,
          Cookies.get("token")
        )
        .then(response => {
          if (response.status == 200 || response.status == 204) {
            this.mandate = response.data;
          } else {
            this.mandate = null;
          }
        })
        .catch(error => {
          this.mandate = null;
        });
    },
    addIBAN() {
      this.addIbanBool = true;
      this.ibanData = {
        allProducts: this.allProducts,
        bankBranch: null,
        iban: "",
        societyId: this.companyDetails.company_id,
        productId:
          this.environment === "paper"
            ? this.selectedProduct.paper
            : this.selectedProduct.card
      };
      this.customerIBAN.push(this.ibanData);
      this.editMode = true;
    },
    setEnvironment(env) {
      this.environment = env;
      this.editMode = false;
      if (
        this.filterCustomerIBAN &&
        this.filterCustomerIBAN.bankBranch === null
      ) {
        this.addIbanBool = true;
      } else {
        this.addIbanBool = false;
      }
    }
  },
  mounted() {
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
  },
  computed: {
    confirmPasswordStyle() {
      return this.confirmPassword === ""
        ? ""
        : this.newPassword === this.confirmPassword
          ? ""
          : "outline-color: #ff0000a6;";
    },
    filterManageCompany: function () {
      if (this.selectedManageCompany !== "") {
        const result = this.manageCompanies.filter(
          x => x.company_id === this.selectedManageCompany.company_id
        );
        return result;
      } else {
        return this.manageCompanies;
      }
    },
    filterOrderPerson: function () {
      if (this.orderPersons && this.selectedProduct) {
        const paperResult = this.orderPersons.filter(
          x => x.product === this.selectedProduct.paper
        );
        const cardResult = this.orderPersons.filter(
          x => x.product === this.selectedProduct.card
        );
        if (this.environment === "paper") {
          return paperResult[0];
        } else {
          return cardResult[0];
        }
      }
      return [];
    },
    filterCustomerIBAN: function () {
      if (this.customerIBAN && this.selectedProduct) {
        const paperResultIBAN = this.customerIBAN.filter(
          x => x.productId === this.selectedProduct.paper
        );
        const cardResultIBAN = this.customerIBAN.filter(
          x => x.productId === this.selectedProduct.card
        );
        if (this.environment === "paper") {
          return paperResultIBAN[0];
        } else {
          return cardResultIBAN[0];
        }
      }
      return [];
    },
    mandateStatus() {
      return this.companyDetails &&
        !this.companyDetails.state_owned &&
        this.companyDetails.user_config &&
        this.companyDetails.user_config.roles &&
        this.companyDetails.user_config.roles.includes("EDITOR")
        ? "V"
        : this.mandate
          ? this.mandate.status
          : "N";
    }
  },
  created() {
    this.url = Cookies.get("userLink");
    this.token = Cookies.get("token");
    this.resetData();
    if (
      !this.companyDetails.user_config ||
      !this.companyDetails.user_config.roles ||
      !this.companyDetails.user_config.roles.includes("EDITOR")
    ) {
      this.getMandateStatus();
    }
    this.selectProduct(0);
    if (this.userRole === "CST") {
      this.getOrderPerson();
    }
    this.getCustomerIBAN();
    // this.getManageCompanies();
  }
};
</script>
<style scoped src="bootstrap/dist/css/bootstrap.css"></style>
<style scoped src="bootstrap-vue/dist/bootstrap-vue.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="@/assets/styles/multiselect.css"></style>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped>
.bottom-0 {
  bottom: 0;
}

.right-0 {
  right: 0;
}

.payVATCheckbox {
  line-height: 27px;
}

.disabled-input-file {
  background: transparent;
  color: #f49400;
  border: 1px solid #f49400;
}

.modal-popup-content-notif {
  max-width: 1400px;
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #fff;
  margin: 0px auto;
  position: relative;
  text-align: center;
}

.content {
  width: 95%;
  margin: 20px auto 20px auto;
}
</style>
<style>
.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: #007bff;
  background-color: #007bff;
}
</style>
